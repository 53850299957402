.download-page{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 600px;
    background-color: #fff;
    position: relative;
}

.hexagon-yellow{
    position: absolute;
    top: 0;
    left: 0;
}

.hexagon-green{
    position: absolute;
    right: 0;
    bottom: 0;
}


.banking-box-left h1{
    color: #0a0a0a;
    font-weight: 500;
    font-size: 30px;
    width: 451px;
}

.banking-box-left p{
    color: #0a0a0a;
    font-weight: 350;
    font-size: 18px;
    width: 451px;
    margin: 40px 0px;
}

.download-box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.download-phone-img{
    width: 580px;
    object-fit: contain;
}

@media screen and (max-width: 600px){
     .banking-box-left{
        width: 268px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
     .banking-box-left h1{
        font-size: 30px;
        width:268px;
        text-align: center;
    }
   .banking-box-left p{
        width:268px;
        text-align: center;
        font-size: 18px;
        margin: 10px;
   }
   .banking-box-left a{
        margin-top: 20px;
    }
    .hexagon-yellow{
        width: 400px;
    }
    .hexagon-green{
        width: 100px;
    }
    .download-phone-img{
       display: none;
    }
    .download-box{
        flex-direction: column;
        gap: 10px;
    }
}

