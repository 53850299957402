.community{
    width: 100%;
    height: 1000px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 30px 113px;
}

.community-box>img{
    width: 580px;
    height: 690px;
    border-radius: 10px;
}
.community>h1{
    color: #000;
    font-size: 48px;
    text-align: center;
    font-weight: 500;
}

.community-box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 800px;
    margin-top: 100px;
    gap: 80px;
}

.community-box-text h1{
    width: 485px;
    color: #000;
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 31px;
}

.community-box-text p{
    width: 485px;
    color: #000;
    font-weight: 350;
    font-size: 18px;
    line-height: 44px;
    margin-bottom: 38px;
}

.community-box-text p span{
    font-style: italic;
    color: #000;
    font-weight: 400;
    font-size: 18px;
}


@media screen and (max-width: 1300px){
    .community{
        width: 100%;
        height: 1000px;
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 30px 113px;
    }
    
    .community-box>img{
        width: 480px;
        height: 590px;
        border-radius: 10px;
    }
    .community>h1{
        color: #000;
        font-size: 48px;
        text-align: center;
        font-weight: 700;
    }
    
    .community-box{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 800px;
        margin-top: 50px;
        gap: 80px;
    }
    
    .community-box-text h1{
        width: 485px;
        color: #000;
        font-weight: 500;
        font-size: 32px;
        margin-bottom: 31px;
    }
    
    .community-box-text p{
        width: 485px;
        color: #000;
        font-weight: 350;
        font-size: 18px;
        line-height: 44px;
        margin-bottom: 38px;
    }

    .community-box-text p span{
        font-style: italic;
        color: #000;
        font-weight: 400;
        font-size: 18px;
    }
    
}

@media screen and (max-width:1200px){
    .community{
        height: 1300px;
    }

    .community-box{
        flex-direction: column;
        margin-top: 180px;
    }

    .community-box-text{
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    .community-box-text h1{
        width: 100%;
        color: #000;
        font-weight: 500;
        font-size: 32px;
        margin-bottom: 31px;
    }
    
    .community-box-text p{
        width: 100%;
        color: #000;
        font-weight: 350;
        font-size: 18px;
        line-height: 44px;
        margin-bottom: 38px;
    }

    .community-box-text p span{
        font-style: italic;
        color: #000;
        font-weight: 400;
        font-size: 18px;
    }
}

/* @media screen and (max-width: 1024px){
    .community{
        padding: 10px 33px;
    }
    .community>img{
        width: 80%;
    }
    .community-box-text h1{
        font-size: 22px;
        width: 320px;
    }
    .community-box-text p{
        width: 300px;
    }
} */



@media screen and (max-width: 600px){
    .community{
        height: 1350px;
        padding: 10px 30px;
    }
    .community>h1{
        font-size: 21px;
    }
    .community-box{
        flex-direction: column;
        margin-top: 80px;
    }
    .community-box>img{
        width: 350px;
        margin-top: 300px;
    }
    .community-box-text{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px 33px;
    }
    .community-box-text h1{
        width: 320px;
        font-size: 22px;
        text-align: center;
        font-weight: 500;
    }
    .community-box-text p{
        width: 320px;
        text-align: center;
        font-size: 18px;
    }
}