.picture-carousel{
    width: 766.4px;
    height: 700px;
    background-color: #f5f5f5;
    padding: 30px 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.carousel-container{
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.picture-carousel .carousel-caption{
    background-color: #000;
    width: 705px;
    max-width: 705px;
    left: 0;
    bottom: 0px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 33px;
}
.picture-carousel .carousel-caption>h4{
    font-size: 18px;
    font-weight: 350;
}

.picture-carousel .carousel-caption>h4 span{
    font-size: 18px;
    font-weight: 700;
 }

.picture-carousel .carousel-indicators{
    bottom: 140px;
}

.picture-carousel .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.picture-carousel .carousel-indicators .active{
    background-color: #4CC800;
}

.picture-carousel .carousel-item>img{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 33.33%, rgba(0, 0, 0, 0.75) 88.02%);
    width: 705px;
}

@media screen and (max-width: 600px){
    .picture-carousel{
        height: 600px;
    }
    .picture-carousel .carousel-caption{
        padding: 10px 13px;
        width: 100%;
    }
    .picture-carousel .carousel-item>img{
        width: 350px;
        object-fit: cover;
        border-radius: 10px;
    }
    .picture-carousel .carousel-caption>h4{
        font-size: 12px;
    }
}