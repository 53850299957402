.Navbar {
    width: 100%;
    height: 60px;
    background-color: #050505;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 41px 80px;
    position: fixed;
    top: 0;
    z-index: 20;
}

.Navbar .left-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.Navbar .left-side .logo img {
    width: 200px;
}

.Navbar .cta {
    margin-right: 80px;
}


.webapp-btn{
    border: 1px solid rgba(76, 200, 0, 1) ;
    padding: 10px 25px;
    border-radius: 20px;
    color: rgba(76, 200, 0, 1);
    background-color: black;
}

/* .Navbar .cta .login-btn:hover {
    color: rgba(76, 200, 0, 1);
} */

.Navbar .btn1 {
    margin-left: 20px;
    border: 1px solid rgba(76, 200, 0, 1);
    color: rgba(76, 200, 0, 1);
    padding: 10px 22px;
    border-radius: 30px;
}

.Navbar .btn1:hover {
    color: #fff;
}

.Navbar .nav-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 30px;
    gap: 48px;
}

.Navbar .nav-links li {
    margin-left: 48px;
}

.Navbar .nav-links li a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

.Navbar .nav-links li a:hover {
    color: rgba(76, 200, 0, 1);
}

.Navbar .nav-links li a:active {
    color: rgba(76, 200, 0, 1);
    font-weight: 500;
}

.Navbar .mobile-menu {
    display: none;
}

.Navbar .nav-links li a .active {
    color: rgba(76, 200, 0, 1)
}

@media screen and (max-width: 1300px) {
    .Navbar {
        padding: 41px 60px;
    }
    .Navbar .cta {
        margin-right: 20px;
    }
    .Navbar .nav-links {
        margin: 0px 30px 0px 10px;
    }
    .Navbar .nav-links li {
        margin-left: 48px;
    }

    .Navbar .nav-links {
        gap: 25px;
    }
    .Navbar .left-side .logo img {
        width: 160px;
    }
    .Navbar .nav-links li a {
        font-size: 12px;
    }
}

@media screen and (max-width:1080px) {
    .Navbar .nav-links,
    .Navbar .cta {
        display: none;
    }
    .Navbar .mobile-menu {
        display: block;
        font-size: 25px;
    }
}

@media screen and (max-width: 600px) {
    .Navbar .nav-links,
    .Navbar .cta {
        display: none;
    }
    .Navbar .mobile-menu {
        display: block;
        font-size: 25px;
    }

    .Navbar{
        padding: 41px 20px;
    }
}