.new-merchant{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F9F9F9;
    width: 100%;
    height: 1000px;
    z-index: 5;
    position: relative;
    padding: 10px 80px;
}

.new-merchant>h1{
    color: #000;
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    width: 926px;
}

.new-merchant h1 span{
    color: #4CC800;
}

.new-merchant>p{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #000000;
    max-width: 954px;
    margin-top: 40px;
}

.new-merchant .new-merchant-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 77px;
    margin-top: 80px;
    flex-wrap: wrap;
}

.new-merchant .new-merchant-container .new-merchant-box{
    width: 348px;
    height: 348px;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 10px 10px 40px -10px rgba(93, 120, 107, 0.25);
    border-radius: 10px;
    position: relative;
    padding-top: 20px;
}


.new-merchant .new-merchant-container .new-merchant-box h1{
    font-weight: 700;
    font-size: 30px;
    color: #000;
    margin-bottom: 21px;
}

.new-merchant .new-merchant-container .new-merchant-box p{
    font-weight: 350;
    font-size: 18px;
    color: #000;
    text-align: center;
    margin-bottom: 31px;
    padding: 5px 55px;
}

.new-merchant .half-circle {
  width: 110px;
  height: 110px;
  z-index: 2;
  background-color: #F9F9F9;
  position: absolute;
  border-radius: 50%;
  top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-merchant .half-circle-box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 50%;
}

.new-merchant .green-line{
    width: 240px;
    background-color: #4CC800;
    height: 5px;
    position: absolute;
    bottom: 60px;
}


@media screen and (max-width: 1300px){
    .new-merchant{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #F9F9F9;
        width: 100%;
        height: 900px;
        z-index: 5;
        position: relative;
        padding: 10px 80px;
    }
    .new-merchant{
        padding: 10px 60px;
    }
    
    .new-merchant>h1{
        font-size: 40px;
    }
    
    .new-merchant>p{
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #000000;
        max-width: 954px;
    }
    
    .new-merchant .new-merchant-container{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 77px;
        margin-top: 80px;
        flex-wrap: wrap;
    }
    
    .new-merchant .new-merchant-container .new-merchant-box{
        width: 318px;
        height: 318px;
        padding-top: 20px;
    }
    
    
    .new-merchant .new-merchant-container .new-merchant-box h1{
        font-size: 22px;
        margin-bottom: 16px;
    }
    
    .new-merchant .new-merchant-container .new-merchant-box p{
        font-weight: 350;
        font-size: 15px;
    }
    
}

@media screen and (max-width: 1024px){
    .new-merchant{
        height: 1200px;
    }
    .new-merchant .new-merchant-container .new-merchant-box{
        width: 300px;
        height: 300px;
        padding-top: 20px;
    }
}

@media screen and (max-width: 900px){
    .new-merchant .new-merchant-container .new-merchant-box{
        width: 285px;
        height: 318px;
    }
}


@media screen and (max-width: 600px){
.new-merchant{
    height: 1750px;
    margin-top: 210px;
}
.new-merchant>h1{
    font-size: 30px;
    width: 350px;
}
.new-merchant>p{
    padding: 5px 33px;
    margin-top: 34px;
    width: 340px;
}
.new-merchant .new-merchant-container{
    flex-direction: column;
}

.new-merchant .new-merchant-container .new-merchant-box{
    width: 318px;
    height: 318px;
    padding-top: 20px;
}
}