.new-banner{
    width: 100%;
    height: 876px;
    background-color: #0A0A0A;
    padding: 10px 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-banner .banner-contents{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 600px;
    gap: 104px;
    max-width: 1400px;
}

.new-banner-text{
    margin-top: -10px;
}



.new-banner-text h1{
    font-size: 48px;
    width: 332px;
    font-weight: 500;
}

.new-banner-text h1 span{
    color: #4CC800;
}

.new-banner-img1{
    border-radius: 10px;
    width: 750px;
    height: 541px;
}

.new-banner-text p{
    margin-top: 43px;
    margin-bottom: 48px;
    font-weight: 350;
    font-size: 18px;
    color: white;
    width: 357px;
}


.new-banner-text a{
    margin-top: 48px;
    text-decoration: none;
    color: white;
    padding: 15px 43px;
    font-size: 16px;
    background-color: #4CC800;
    border-radius: 5px;
}

.new-banner .banner-hexagon{
    position: absolute;
    right: 0;
    bottom: -200px;
    z-index: 2;
}

@media screen and (max-width: 1024px){
    .new-banner{
        height: 1136px;
    }
    .new-banner .banner-contents{
        flex-direction: column-reverse;
        justify-content: center;
        height: 1136px;
        align-items: center;
    }
    .new-banner-text{
        margin-top: 90px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .new-banner-text h1{
        font-size: 42px;
        width: 600px;
        font-weight: 800;
    }
    .new-banner-text p{
        font-size: 22px;
        width: 500px;
    }
}

@media screen and (max-width: 1300px){
    
    .new-banner-text h1{
        font-size: 45px;
    }
    
    .new-banner-text p{
        margin-top: 43px;
        margin-bottom: 48px;
        font-weight: 350;
        font-size: 15px;
        color: white;
        width: 357px;
    }
    
    
    .new-banner-text a{
        margin-top: 48px;
        text-decoration: none;
        color: white;
        padding: 15px 43px;
        font-size: 14px;
        background-color: #4CC800;
        border-radius: 5px;
    }
    
    .new-banner .banner-hexagon{
        position: absolute;
        right: 0;
        bottom: -200px;
        z-index: 2;
    }
}


@media screen and (max-width: 600px){
    .new-banner{
        height: 1136px;
    }
    .new-banner .banner-contents{
        flex-direction: column-reverse;
        justify-content: center;
        height: 1136px;
    }
    .new-banner-text{
        margin-top: 0;
        text-align: center;
    }
    .new-banner .banner-hexagon{
        bottom: -50px;
        width: 260px;
    }

    .new-banner-img1{
        width: 364px;
        height: 243px;
    }

    .new-banner-text p{
        font-size: 22px;
        width: 340px;
    }
    .new-banner-text h1{
        font-size: 42px;
        width: 320px;
        font-weight: 800;
    }
}