.how-it-works{
    width: 100%;
    height: 1200px;
    background-color: #070707;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 100px;
}

.how-it-works .how-it-works-title{
    margin-bottom: 100px;
}

.how-it-works>h1{
    font-weight: 350;
    font-size: 40px;
    margin-top: 80px;
}

.how-it-works>h1 span{
   color: #4CC800;
  
}

.how-it-works .how-it-works-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 76px;
    margin-top: 100px;
    flex-wrap: wrap;
}

.how-it-works .how-it-works-container .how-it-works-box{
    width: 348px;
    height: 348px;
    background: #FFFFFF;
    box-shadow: 10px 10px 40px -10px rgba(93, 120, 107, 0.25);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 112px;
}

.how-it-works .how-it-works-container .how-it-works-box .half-circle-box{
    width: 130px;
    height: 130px;
    z-index: 2;
    background-color: #000;
    position: absolute;
    border-radius: 50%;
    top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.how-it-works .how-it-works-container .how-it-works-box .half-circle-box .half-circle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 50%;
}

.how-it-works-box .half-circle-box .half-circle h2{
    font-weight: 900;
    font-size: 48px;
    color: #4CC800;
}

.how-it-works-box h1{
    font-weight: 500;
    font-size: 24px;
    color: #000;
    margin-top: 20px;
    text-align: center;
    width: 288px;
}

.how-it-works-box p{
    font-weight: 350;
    font-size: 18px;
    color: #000;
    text-align: center;
    margin-bottom: 31px;
    padding: 5px 55px;
    width: 307px;
}

.green-line{
    width: 240px;
    background-color: #4CC800;
    height: 5px;
    position: absolute;
    bottom: 60px;
}

.how-it-works-phone{
    width: 500px;
}

@media screen and (max-width: 1400px){
    .how-it-works .how-it-works-container .how-it-works-box{
        width: 300px;
        height: 300px;
        background: #FFFFFF;
        box-shadow: 10px 10px 40px -10px rgba(93, 120, 107, 0.25);
        border-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    .how-it-works .how-it-works-container .how-it-works-box .half-circle-box{
        width: 90px;
        height: 90px;
        z-index: 2;
        background-color: #000;
        position: absolute;
        border-radius: 50%;
        top: -60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .how-it-works .how-it-works-container .how-it-works-box .half-circle-box .half-circle{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        background-color: #fff;
        border-radius: 50%;
    }
    
    .how-it-works-box .half-circle-box .half-circle h2{
        font-weight: 900;
        font-size: 36px;
        color: #4CC800;
    }
    
    .how-it-works-box h1{
        font-weight: 500;
        font-size: 20px;
        color: #000;
        margin-bottom: 21px;
        text-align: center;
        width: 220px;
    }
    
    .how-it-works-box p{
        font-weight: 350;
        font-size: 14px;
        color: #000;
        text-align: center;
        margin-bottom: 31px;
        width: 280px;
    }
    
}

@media screen and (max-width: 1240px){
    .how-it-works{
        height: 1000px;
    }
    .how-it-works .how-it-works-container .how-it-works-box{
        width: 250px;
        height: 250px;
    }

    .green-line{
        width: 180px;
        background-color: #4CC800;
        height: 5px;
        bottom: 50px;
    }
}



@media screen and (max-width: 1080px){
    .how-it-works{
        height: 1500px;
    }
}

@media screen and (max-width: 790px){
    .how-it-works{
        height: 2800px;
    }
}

@media screen and (max-width: 600px){
    .how-it-works{
        height: 2800px;
    }
    .how-it-works .how-it-works-title{
        width: 268px;
        margin-bottom: 80px;
    }
    .how-it-works>h1{
        font-size: 24px;
        text-align: center;
        margin-bottom: 50px;
    }
    .how-it-works .how-it-works-container .how-it-works-box{
        width: 316px;
    }
    .how-it-works-phone{
        width: 350px;
    }
}