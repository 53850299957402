.new-features{
    width: 100%;
    height: 876px;
    background-color: #000;
    height: 876px;
    padding: 50px 60px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
}

.new-features .new-features-heading{
    margin-top: 180px;
}


.new-features .new-features-heading>h1{
    font-size: 48px;
    font-weight: 500;
    text-align: center;
}

.new-features .new-features-heading>p{
    font-size: 18px;
    text-align: center;
}

.new-features .new-features-heading>p span{
    color: #4CC800;
}

.new-features-container{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    gap: 50px;
    height: 730px;
    margin-top: 30px;
}
.add-gap{
    margin-bottom: -30px;
}

.new-features-container .merchant-services{
    width: 314px;
    height: 555px;
    background-color: #0A0A0A;
    box-shadow: 10px 10px 21px 5px rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0px;
    padding: 30px 5px;
}

.merchant-services .merchant-header{
    width: 201px;
    height: 55px;
    background-color: #1B1B1B;
    box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.25);
    border-radius: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 5px 10px;
    position: absolute;
    top: -20px;
    margin-bottom: 20px;
}

.merchant-header .green-dot{
    width: 15.25px;
    height: 15.25px;
    background: #4CC800;
    border-radius: 50%;
}

.merchant-body .merchant-body-det{
    margin-bottom: 30px;
}

.merchant-header>p{
    margin: 0;
}

.merchant-body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0px 30px;
}
.merchant-body .heading h1{
    font-weight: 400;
    font-size: 16px;
}

.new-features .new-feature-phone{
    width: 395px;
}

.merchant-body .body p{
    font-weight: 350;
    font-size: 13px;
    margin-top: 10px;
    width: 230px;
}

@media screen and (max-width: 1300px){
    
    .new-features>h1{
        font-size: 40px;
    }
}

@media screen and (max-width: 600px){
    .new-features{
        height: 2000px;
        gap: 0px;
        justify-content: flex-start;
    }

    .new-features>h1{
        text-align: center;
    }
    .new-features>p{
        text-align: center;
    }
    .new-features-container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 1238px;
    }
    .new-features .new-feature-phone{
        position: absolute;
        bottom: -521px;
        width: 320px;
    }
    .new-features .new-features-heading {
        margin-top: 0px;
    }
}