.mobile-nav {
    width: 100%;
    max-width: 800px;
    height: 100vh;
    background-color: #000;
    position: fixed;
    right: 0;
    top: 60px;
    z-index: 20;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
}

.mobile-nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 60px;
}

.mobile-nav-links li {
    margin-top: 40px;
}

.mobile-nav-links li a {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
}

.mobile-nav-links li a:hover {
    color: rgba(76, 200, 0, 1);
}

.mobile-nav-links li a:active {
    color: rgba(76, 200, 0, 1);
}

.mobile-nav .mobile-cta {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.mobile-nav .mobile-nav-links .mobile-cta .login-btn {
    color: #fff;
    margin-top: 40px;
}

.mobile-nav .mobile-nav-links .mobile-cta .login-btn:hover {
    color: rgba(76, 200, 0, 1);
}

.mobile-nav .mobile-nav-links .mobile-cta .btn1 {
    border: 1px solid rgba(76, 200, 0, 1);
    color: rgba(76, 200, 0, 1);
    padding: 10px 22px;
    border-radius: 30px;
    margin-top: 40px;
}

.mobile-nav-links .btn1:hover {
    color: #fff;
}

