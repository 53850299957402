.new-featured{
    width: 954px;
    height: 144px;
    background-color: white;
    box-shadow: 10px 10px 40px -10px rgba(93, 120, 107, 0.25);
    border-radius: 10px;
    position: absolute;
    bottom: -70px;
    padding: 10px 30px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.new-featured>h1{
    font-weight: 350;
    font-size: 18px;
    color: #000;
    position: relative;
    top: 30px;
}
.new-featured .logo-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-featured .logo-box img {
    margin-right: 25px;
    
}

.featured-paper-img{
    width: 200px;
    object-fit: contain;
}

@media screen and (max-width: 1300px){
    .new-featured{
        width: 854px;
    } 
    .featured-paper-img{
        width: 150px;
        height: 80px;
    }
}
@media screen and (max-width: 1024px){
    .new-featured{
        width: 850px;
    }
}

@media screen and (max-width: 600px){
    .new-featured{
        width: 320px;
        height: 380px;
        bottom: -285px;
    }

    .new-featured>h1{
        top: 10px;
    }

    .new-featured .logo-box{
        flex-direction: column;
    }
}