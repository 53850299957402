.about-banner{
    width: 100%;
    height: 876px;
    background-color: #0A0A0A;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 112px;
    gap: 104px;
}

.about-banner .banner-left{
    width: 485px;
}

.about-banner .banner-left h1{
    font-weight: 500;
    font-size: 32px;
    color: #fff;
    margin-bottom: 35px;
    line-height: 36px;
}

.about-banner .banner-left p{
    font-weight: 350;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 35px;
}

.banner-right>img{
    width: 607px;
}


.vis-mission{
    width: 100%;
    height: 557px;
    background-image: url('https://res.cloudinary.com/teb/image/upload/v1671132658/PennyTree_Logo_Mashup_3840_x_2160_ho8eds.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 130px;
}

.vis-mission .vis-mis-box{
    width: 348px;
    height: 348px;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 10px 10px 40px -10px rgba(93, 120, 107, 0.25);
    border-radius: 10px;
    position: relative;
}


.vis-mission .vis-mis-box .half-circle{
    width: 110px;
    height: 110px;
    z-index: 2;
    background-color: #F9F9F9;
    position: absolute;
    border-radius: 50%;
    top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vis-mission .vis-mis-box .half-circle .half-circle-box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 50%;
}

.vis-mission .vis-mis-box h1{
    font-weight: 700;
    font-size: 24px;
    color: #000;
    margin-bottom: 21px;
}

.vis-mission .vis-mis-box p{
    font-weight: 350;
    font-size: 18px;
    color: #000;
    text-align: center;
    margin-bottom: 31px;
    padding: 5px 55px;
}

.core-values{
    width: 100%;
    background-color: #0A0A0A;
    height: 756px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.core-values>h1{
    font-size: 30px;
    font-weight: 500;
}

.core-values-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
    margin-top: 88px;
    flex-wrap: wrap;
}

.core-values-container .core-values-box{
    width: 314px;
    height: 245px;
    background-color: #0A0A0A;
    box-shadow: 10px 10px 21px 5px rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 17px;
}

.core-values-box .core-values-header{
    width: 201px;
    height: 55px;
    background-color: #1B1B1B;
    box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.25);
    border-radius: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 5px 10px;
    position: absolute;
    top: -20px;
    margin-bottom: 20px;
}

.core-values-header .green-dot{
    width: 15.25px;
    height: 15.25px;
    background: #4CC800;
    border-radius: 50%;
}

.core-values-header>p{
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}

.core-values-box h3{
    font-weight: 400;
    font-size: 24px;
    text-align: center;
}

.ceo{
    width: 100%;
    height: 940px;
    background-color: #070707;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 104px;
    padding: 10px 112px;
}
.ceo .ceo-left{
    width: 100%;
}

.ceo .ceo-left>h1{
    font-weight: 500;
    font-size: 44px;
    color: #000;
    margin-bottom: 25px;
    color: #4CC800;
}

.ceo .ceo-left>h4{
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 20px;
    color: #fff
}

.ceo .ceo-left>p{
    font-weight: 350;
    font-size: 18px;
    color: #fff;
    text-align: left;
    margin-bottom: 15px;
}

.ceo .ceo-left>h5{
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
}

.ceo-right-text{
    display: none;
}

.ceo-right>img{
    width: 607px;
}

@media screen and (max-width: 1300px){
    .about-banner{
        padding: 30px 80px;
        gap: 80px;
        height: 876px;
    }
    .banner-right>img, .ceo-right>img{
        width: 450px;
    }
    .core-values{
        height: 556px;
    }

    .core-values-container .core-values-box{
        width: 290px;
        height: 225px;
    }

    .core-values-box h3{
        font-size: 16px;
        width: 250px;
    }
    .ceo{
        height: 900px;
    }


    .ceo>h1{
        font-size: 36px;
    }
    
    .ceo>h4{
        font-weight: 500;
        font-size: 24px;
        color: #000;
        margin-bottom: 60px;
        color: #535456
    }
    
    .ceo>p{
        font-weight: 350;
        font-size: 14px;
        color: #000;
        line-height: 44px;
        width: 850px;
        text-align: left;
        margin-bottom: 30px;
    }
    .ceo-right-text{
        display: none;
    }
}

@media screen and (max-width: 850px){
     .about-banner {
        height: 546px;
        padding: 30px 60px;
    }

    .banner-right>img, .ceo-right>img {
        width: 300px;
    }
    .about-banner .banner-left h1 {
        font-size: 24px;
    }
    .about-banner .banner-left p {
        font-size: 14px;
    }
    .ceo .ceo-left>h1 {
        font-size: 22px;
    }
    .ceo .ceo-left>h4 {
        font-size: 16px;
    }
    .ceo .ceo-left>p {
        font-size: 12px;
    }
    .ceo {
        height: 570px;
        padding: 30px 60px;
    }
}

@media screen and (max-width: 600px){
    .about-banner{
        flex-direction: column-reverse;
        height: 1124px;
        padding: 10px 33px;
    }
    .about-banner .banner-right>img{
        width: 350px;
        height: 400px;
        border-radius: 10px;
        object-fit: cover;
    }
    .about-banner .banner-left{
        width: 100%;
        text-align: center;
    }
    .about-banner .banner-left p{
        line-height: 36px;
    }
    .vis-mission{
        flex-direction: column;
        height: 1000px;
    }
    .core-values{
        height: 1200px;
    }
    .core-values-container{
        flex-direction: column;
        justify-content: center;

    }
    .ceo{
        height: 1290px;
        width: 100%;
        padding: 10px 33px;
        flex-direction: column;
    }
    .ceo .ceo-left{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .ceo .ceo-right>img{
        width: 100%;
        margin-top: -80px;
    }

    .ceo .ceo-left>h1{
        font-size: 30px;
        text-align: center;
    }

    .ceo .ceo-left>h4{
        font-size: 24px;
        text-align: center;
    }
    .ceo .ceo-left>p{
        text-align: center;
    }
    .ceo-right-text{
        display: block;
        text-align: center;
        margin-top: 20px;
    }

    .ceo-right-text>h5{
        font-size: 20px;
        color: #070707;
        text-transform: uppercase;
    }

    .ceo-right-text>p{
        font-size: 18px;
        color: #535456;
    }
    .for-web{
        display: none;
    }
}