.new-footer{
    width: 100%;
    height: 640px;
    background-color: #000;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px 100px;
    padding: 84px 122px;
}

.new-footer .footer-logo{
    width: 180px;
}

.new-footer .footer-links-container{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 30px;
}

.new-footer .footer-links-container .footer-links>h3{
    font-weight: 700;
    font-size: 14px;
    color: #535456;
    margin-bottom: 30px;
}

.footer-links a{
    color: #fff;
}

.footer-links a:hover{
    color: #fff;
}

.new-footer .footer-links-container .footer-links p{
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}

.footer-links .social-icons{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.footer-links .social-icons .soc-icon{
    font-size: 20px;
}

.new-footer .bottom-footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
}

.bottom-footer p{
    font-weight: 500;
    font-size: 16px;
    color: #535456;
}

.bottom-footer .app-icon-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bottom-footer .app-icon-container h3{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.bottom-footer .app-icons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 300px;
}

.app-icons .app-icon{
    width: 150px;
}



@media screen and (max-width: 600px){
    .new-footer{
        height: 1400px;
        justify-content: flex-start;
        align-items: center;
    }

    .new-footer .footer-links-container{
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 48px;
        flex-wrap: nowrap;
    }

    .bottom-footer p {
        width: 200px;
        text-align: center;
    }

    .new-footer .bottom-footer{
        flex-direction: column;
        justify-content: center;
        gap: 60px;
        margin-top: 50px;
        flex-wrap: nowrap;
    }

    .new-footer .footer-links-container .footer-links{
        margin-bottom: 44px;
    }
}