.meet-merchants{
    width: 100%;
    height: 700px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 50px;
}


.meet-merchants .meet-merchants-container{
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-auto-columns: 30%;
    margin-top: 50px;
    gap: 40px;
    overflow-x: auto;
    overflow-y: hidden;
    overscroll-behavior-inline: contain;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
    height: 600px;
}

.meet-merchant-box>img{
    height: 400px;
}

.meet-merchants .meet-merchants-container::-webkit-scrollbar {
    display: none;
  }


.meet-merchants .meet-merchants-container .meet-merchant-box{
    width: 357px;
    height: 474px;
    background-color: #f9f9f9;
}

.meet-merchants>h1{
    font-weight: 500;
    font-size: 48px;
    color: #000000;
}

.meet-merchant-box>h1{
    font-weight: 700;
    font-size: 20px;
    color: #000;
    text-align: center;
    margin-top: 10px;
}

.meet-merchant-box>p{
    font-weight: 400;
    font-size: 16px;
    color: #A3A3A3;
    text-align: center;
    margin-top: 10px;
}

.meet-merchant-box>img{
    border-radius: 5px;
}

@media screen and (max-width: 1300px){
    .meet-merchants{
        height: 600px;
    }
.meet-merchants .meet-merchants-container{
        display: grid;
        width: 100%;
        grid-auto-flow: column;
        grid-auto-columns: 30%;
        margin-top: 50px;
        gap: 50px;
        overflow-x: auto;
        overflow-y: hidden;
        overscroll-behavior-inline: contain;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none; 
        height: 500px;
    }
    
    .meet-merchant-box>img{
       width: 300px;
       height: 320px;
    }
    
    .meet-merchants .meet-merchants-container::-webkit-scrollbar {
        display: none;
      }
    
    
    .meet-merchants .meet-merchants-container .meet-merchant-box{
        width: 337px;
        height: 400px;
        background-color: #f9f9f9;
    }
    
    .meet-merchants>h1{
        font-weight: 500;
        font-size: 36px;
        color: #000000;
    }
    
    .meet-merchant-box>h1{
        font-weight: 700;
        font-size: 18px;
        color: #000;
        text-align: center;
        margin-top: 10px;
    }
    
    .meet-merchant-box>p{
        font-weight: 400;
        font-size: 14px;
        color: #A3A3A3;
        text-align: center;
        margin-top: 10px;
    }
    
    .meet-merchant-box>img{
        border-radius: 5px;
    }
}

@media screen and (max-width: 600px){
    .meet-merchants{
        height: 600px;
    }
    .meet-merchants .meet-merchants-container{
        display: flex;
    }

    .meet-merchants>h1{
        font-size: 30px;
        text-align: center;
    }
}


