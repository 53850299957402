.Banner {
    width: 100%;
    height: 128vh;
    background-color: #050505;
    position: relative;
    padding: 5px 20px;

}
.homepage{
    overflow-x: hidden;
}

.about-us-page{
    overflow-x: hidden;
}

.phone-banner {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-30%, -45%);
}

.Banner .banner-contents {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-58%, -50%);
    width: 100%;
    text-align: center;
}

.Banner .banner-contents h1 {
    font-size: 125px;
    font-weight: 700;
}

.Banner .banner-contents span {
    font-size: 40px;
    margin-left: 180px;
    font-weight: 700;
}

.Banner .banner-contents span .btn2 {
    font-size: 16px;
    border: 1px solid rgba(76, 200, 0, 1);
    color: #ffffff;
    padding: 10px 22px;
    border-radius: 30px;
    margin-left: 15px;
    background-color: rgba(76, 200, 0, 1);
}

@media screen and (max-width: 1300px) {
    .Banner{
        height: 153vh;
    }
    .Banner .banner-contents h1 {
        font-size: 80px;
        font-weight: 600;
    }
    .Banner .banner-contents span {
        font-size: 30px;
        margin-left: 190px;
        font-weight: 700;
    }
    .phone-banner {
        top: 50%;
    }
    .Banner .banner-contents {
        top: 54%;
    }
}

@media screen and (max-width:1080px) {
    .Banner {
        height: 77vh;
    }
    .Banner .banner-contents h1 {
        font-size: 40px;
        font-weight: 600;
    }
    .Banner .banner-contents span {
        font-size: 20px;
        margin-left: 190px;
        font-weight: 700;
    }
    .Banner .banner-contents {
        top: 54%;
    }
    .Banner .banner-contents span .btn2 {
        font-size: 14px;
        padding: 8px 15px;
    }
    .phone-banner{
        width: 500px;
    }
}

@media screen and (max-width: 600px) {
    .Banner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 60vh;
    }
    .phone-banner {
        width: 100%;
    }
    .spiral-banner {
        width: 100%;
    }
    .Banner .banner-contents h1 {
        font-size: 40px;
        font-weight: 700;
    }
    .Banner .banner-contents span {
        font-size: 14px;
        margin-left: 180px;
        font-weight: 700;
    }
    .Banner .banner-contents span .btn2 {
        font-size: 7px;
        margin-left: 5px;
    }
    .Banner .banner-contents span {
        margin-left: 0px;
    }
    .Banner .banner-contents {
        margin-left: 10px;
    }
}