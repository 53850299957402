.terms {
    width: 100%;
    height: auto;
    background-color: #000;
    color: #fff;
    padding: 30px 120px;
}

.terms>h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.terms .term-section h1 {
    font-size: 22px;
    margin: 30px 0 10px 0;
}

.terms .term-section p {
    font-size: 16px;
}