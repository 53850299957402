.no-page{
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    padding: 10px 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-page h2{
    color: #000;
    font-size: 48px;
}

.get-app-btn{
    text-decoration: none;
    color: white;
    padding: 15px 43px;
    font-size: 16px;
    background-color: #4CC800;
    border-radius: 5px;
    margin-top: 50px;
}